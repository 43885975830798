import './Card.css'
import { motion, useAnimation, } from "framer-motion"

export function Card(props) {

    const iconclick = () => {
        props.onClick(props.data.id)
    }

    return (
        <motion.div className="card">
            <motion.img
                layoutId={"icon_" + props.data.id}
                whileTap={{ scale: 1 }}
                whileHover={{scale: 1.1}}
                className="icon"
                src={props.data.icon}
                onClick={() => iconclick()}
            />
            <motion.h4 layoutId={"name" + props.data.id} className="name">{props.data.name}</motion.h4>
            <motion.p layoutId={"kind_" + props.data.id} className="kind">{props.data.kind}</motion.p>
        </motion.div>
    );
};