import { useEffect, useState } from 'react';
import './App.css';
import { motion, AnimateSharedLayout, } from "framer-motion"
import toast, { Toaster } from 'react-hot-toast';
import MediaQuery from "react-responsive";
import { About } from './component/About/About';
import { Create } from './component/Create/Create';
import { Contact } from './component/Contact/Contact';
import Div100vh from 'react-div-100vh'

function App() {

  const symbols = '!<>-_\\/[]{}—=+*^?#'.split('')

  const symbolslength = 18

  const [first, setFirst] = useState(1.5);
  const [second, setSecond] = useState(1);
  const [third, setThird] = useState(1);

  const [title, setTitle] = useState("ABOUT");
  const [explanation, setExplanation] = useState("自己紹介です。");

  function onScroll() {
    const firstArea = document.getElementById('firstArea').getBoundingClientRect();
    const secondArea = document.getElementById('secondArea').getBoundingClientRect();
    const thirdArea = document.getElementById('thirdArea').getBoundingClientRect();

    if (firstArea.top == 0) {
      setFirst(1.5);
      setSecond(1)
      setThird(1);
      scramble("ABOUT", setTitle);
      scramble("自己紹介", setExplanation);
    } else if (secondArea.top == 0) {
      setFirst(1);
      setSecond(1.5)
      setThird(1);
      scramble("CREATE", setTitle);
      scramble("今まで作った作品", setExplanation);
    } else if (thirdArea.top == 0) {
      setFirst(1);
      setSecond(1)
      setThird(1.5);
      scramble("CONTACT", setTitle);
      scramble("お問い合わせ", setExplanation);
    } else {
      setFirst(1);
      setSecond(1)
      setThird(1);
    }
  }

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  function scramble(text, setState) {

    let rouStr = "";
    let decidStr = ""
    let i = 0;
    let num = text.length;

    let timerId = setInterval(function(){
      for (let i = 0; rouStr.length < num; i++) {
        rouStr += symbols[getRandomInt(symbolslength)]
      }
      setState(rouStr);
      rouStr = decidStr + ""
      i++

      if (i % 10 == 0) {
        decidStr += text.substr((i / 10) - 1, 1);
      }

      if (i == 10 * num) {
        setState(text);
        clearInterval(timerId);
      }  
    }, 10);   
  } 

  function pageClick(i) { 

    switch (i) {
      case 1:
        document.getElementById("firstArea").scrollIntoView(true);
        break;
      case 2:
        document.getElementById("secondArea").scrollIntoView(true);
        break;
      case 3:
        document.getElementById("thirdArea").scrollIntoView(true);        
        break;
      default:
        break;
    };
  }

  return (
    <Div100vh className='App' onScroll={() => {onScroll()}}>
      <main>
      <div class="area">
            <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
    </div >
      <Div100vh className="home">
        <h1 layoutId='name' id="i">jshiota.me</h1>
        <p id="job">- Computer Programmer -</p>
        <div className='menu'>
          <h3 className='menuitem' onClick={() => pageClick(1)}>ABOUT</h3>
          <h3 className='menuitem' onClick={() => pageClick(2)}>CREATE</h3>
          <h3 className='menuitem' onClick={() => pageClick(3)}>CONTACT</h3>
        </div>
        <p id="scroll"><span></span>Scroll</p>
      </Div100vh>
      <div className="Detail">
        <Div100vh className="leftarea">
          <div className="leftitem">
            <h1 id="title">{title}</h1>
            <p style={{marginLeft: "10px"}}>{explanation}</p>
          </div>
        </Div100vh>
        <Div100vh id="right" className="right" onScroll={() => {onScroll()}}>
          <Div100vh id="firstArea" className="rightarea">
            <MediaQuery query="(max-width: 999px)">
              <h3 className='title_mobile'>ABOUT</h3>
            </MediaQuery>
            <About/>
          </Div100vh>
          <Div100vh id="secondArea" className="rightarea">
            <MediaQuery query="(max-width: 999px)">
              <h3 className='title_mobile'>CREATE</h3>
            </MediaQuery>
            <Create/>
          </Div100vh>
          <Div100vh id="thirdArea" className="rightarea">
            <MediaQuery query="(max-width: 999px)">
              <h3 className='title_mobile'>CONTACT</h3>
            </MediaQuery>
            <Contact/>
          </Div100vh>
        </Div100vh>
        <div Class="grpPage">
          <motion.div className="dots"
                      initial={{ scale: 1 }}
                      animate={{ scale: first }}
                      exit={{ scale: 1 }}
                      onClick={() => pageClick(1)}></motion.div>
          <motion.div className="dots"
                      initial={{ scale: 1 }}
                      animate={{ scale: second }}
                      exit={{ scale: 1 }}
                      onClick={() => pageClick(2)}></motion.div>
          <motion.div className="dots"
                      initial={{ scale: 1 }}
                      animate={{ scale: third }}
                      exit={{ scale: 1 }}
                      onClick={() => pageClick(3)}></motion.div>
        </div>
      </div>
      <Toaster position="bottom-center"/>
      </main>
    </Div100vh>
  );
}

export default App;
