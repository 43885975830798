import './Contact.css'
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { init, send } from 'emailjs-com';
import toast, { Toaster } from 'react-hot-toast';

export function Contact() {

    const [ sendFlag, setSendFlag ] = useState(false);

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => sendEMail(data);

    function sendEMail(data) {
        const userID = process.env.REACT_APP_USER_ID;
        const serviceID = process.env.REACT_APP_SERVICE_ID;
        const templateID = process.env.REACT_APP_TEMPLATE_ID;

        const template_param = {
        to_name: data.Name,
        user_email: data.EmailAddress,
        message: data.Message
        };

        init(userID);

        send(serviceID, templateID, template_param).then(() => {
            toast("お問い合わせを送信しました！", 
                    {icon: '🙆',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#FFF',
                    },
                    duration: 2000,})
            setSendFlag(true);
        }).catch((error) => {
            toast(<di>エラーが発生しました。<br/>インターネット接続等をご確認ください。</di>, 
                    {icon: '🙅',
                    style: {
                        borderRadius: '10px',
                        background: '#FF6E6E',
                        color: '#FFF',
                    },
                    duration: 3000,})
            });
        }

    return (
        <div className='grpContact'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <label>Name</label>
                <input {...register("Name")} />
                <br/>
                <br/>
                <label>Email Address<span style={{color: "red"}}>*</span></label>
                <input {...register("EmailAddress", { required: true })} />
                {errors.EmailAddress && <p className='errorMSG'>⚠メールアドレスを入力してください。</p>}
                <br/>
                <br/>
                <label>Message<span style={{color: "red"}}>*</span></label>
                <textarea {...register("Message", { required: true })}/>
                {errors.Message && <p className='errorMSG'>⚠メッセージを入力してください。</p>}
                <input id="submit" disabled={sendFlag} type="submit" />
            </form>
        </div>
    );
};