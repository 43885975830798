import html from "../icon/html.svg";
import css from "../icon/css.svg";
import javascript from "../icon/javascript.svg";
import ionic from "../icon/ionic.svg";
import capacitor from "../icon/capacitor.svg";
import angular from "../icon/angular.svg";
import react from "../icon/react.svg";
import firebase from "../icon/firebase.svg";
import adobe_xd from "../icon/adobe_xd.svg";
import figma from "../icon/figma.svg";
import typescript from "../icon/typescript.svg";
import nextjs from "../icon/nextjs.svg";

export const skills = [
  {
    icon: html,
    name: "HTML",
    since: "2017-",
    level: "★★★★☆",
  },
  {
    icon: css,
    name: "CSS",
    since: "2017-",
    level: "★★★★☆",
  },
  {
    icon: typescript,
    name: "TypeScript",
    since: "2017-",
    level: "★★★★☆",
  },
  {
    icon: react,
    name: "React",
    since: "2020-",
    level: "★★★★☆",
  },
  {
    icon: nextjs,
    name: "NextJS",
    since: "2022-",
    level: "★★★☆☆",
  },
  {
    icon: ionic,
    name: "Ionic",
    since: "2017-",
    level: "★★☆☆☆",
  },
  {
    icon: capacitor,
    name: "Capacitor",
    since: "2017-",
    level: "★★☆☆☆",
  },
  {
    icon: angular,
    name: "Angular",
    since: "2017-",
    level: "★★☆☆☆",
  },
  {
    icon: firebase,
    name: "Firebase",
    since: "2019-",
    level: "★★★☆☆",
  },
  {
    icon: figma,
    name: "figma",
    since: "2020-",
    level: "★★★★☆",
  },
];
