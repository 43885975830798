import './Skill.css'
import { motion, useAnimation, } from "framer-motion"
import html from '../../icon/html.svg'

export function Skill(props) {

    return (
        <div id="grpSkill">
            <div id="iconBorder">
                <img id="icon" src={props.icon}/>
            </div>
            <p className='t' style={{margin: "5px 0 0 0"}}>{props.name}</p>
            <p className='t' style={{margin: "5px 0 0 0"}}>{props.since}</p>
            <p className='t' style={{margin: "5px 0 10px 0"}}>{props.level}</p>
        </div>
    );
};