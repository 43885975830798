import './Create.css'
import { useEffect, useState } from 'react';
import { motion, useAnimation, } from "framer-motion"
import { Card } from '../Card/Card';
import { items } from '../../data/data';
import close from '../../icon/close.svg'
import skill from '../../icon/skill.svg'
import calendar from '../../icon/calendar.svg'
import memo from '../../icon/memo.svg'
import link from '../../icon/link.svg'
import appstore from '../../icon/appstore.svg'
import playstore from '../../icon/playstore.svg'
import globe from '../../icon/globe.svg'

export function Create() {

    const [selectId, setSelectId] = useState(0);

    const iconclick = (id) => {
        setSelectId(id);
      }

    return (
        <div className="rightitem">
          {selectId === 0 &&
            <div className="grid">
              {
                items.map((item) => {
                  return (
                    <Card data={item} onClick={iconclick}/>
                  )
                })
              }
            </div>
          }
          {selectId != 0 &&
            <div className='choice'>
              <div className="detail">
                <motion.img
                    layoutId={"icon_" + selectId}
                    className="icon_detail"
                    src={items[selectId - 1].icon}
                />
                <div>
                  <motion.h1 layoutId={"name" + selectId} className="name_detail">{items[selectId - 1].name}</motion.h1>
                  <motion.p layoutId={"kind_" + selectId} className="kind_detail">{items[selectId - 1].kind}</motion.p>
                </div>
              </div>
              <img id="close" src={close} onClick={() => setSelectId(0)}/>
              <motion.div
                className="item"
                animate={{
                  opacity: [0, 1],
                  y: [50, 0]
                }}
                transition={{ 
                    duration: 0.5,
                    delay: 0.2
                }}>
                <div className="title">
                  <img className="logo" src={calendar}/>
                  <h2>DATE</h2>
                </div>
                <p className="text">{items[selectId - 1].date}</p>
              </motion.div>
              <motion.div
                className="item"
                animate={{
                  opacity: [0, 1],
                  y: [50, 0]
                }}
                transition={{ 
                    duration: 0.5,
                    delay: 0.4
                }}>
                <div className="title">
                  <img className="logo" src={skill}/>
                  <h2>SKILL</h2>
                </div>
                <p className="text">
                  {
                    items[selectId - 1].skills.join(' / ')
                  }
                </p>
              </motion.div>
              <motion.div
                className="item"
                animate={{
                  opacity: [0, 1],
                  y: [50, 0]
                }}
                transition={{ 
                    duration: 0.5,
                    delay: 0.6
                }}>
                <div className="title">
                  <img className="logo" src={memo}/>
                  <h2>OVERVIEW</h2>
                </div>
                <p className="text">{items[selectId - 1].overview}</p>
              </motion.div>
              <motion.div
                className="item"
                animate={{
                  opacity: [0, 1],
                  y: [50, 0]
                }}
                transition={{ 
                    duration: 0.5,
                    delay: 0.8
                }}>
                <div className="title">
                  <img className="logo" src={link}/>
                  <h2>LINK</h2>
                </div>
                <div className="title">
                  {items[selectId - 1].link_ios && <a
                    href={items[selectId - 1].link_ios}
                    rel="noopener noreferrer" 
                    target="_blank"
                    title="AppStore"
                    className="linktag"
                    >
                      <motion.div
                        className="link"
                        whileTap={{ scale: 1 }}
                        whileHover={{scale: 1.1}}
                        >
                        <motion.img src={appstore} className="logo"/>
                        <p>iOS</p>
                      </motion.div>
                  </a>}
                  {items[selectId - 1].link_android && <a
                    href={items[selectId - 1].link_android}
                    rel="noopener noreferrer" 
                    target="_blank"
                    title="GooglePlayStore"
                    className="linktag"
                    >
                      <motion.div
                        className="link"
                        whileTap={{ scale: 1 }}
                        whileHover={{scale: 1.1}}
                        >
                        <motion.img src={playstore} className="logo"/>
                        <p>Android</p>
                      </motion.div>
                  </a>}
                  {items[selectId - 1].link_web && <a
                    href={items[selectId - 1].link_web}
                    rel="noopener noreferrer" 
                    target="_blank"
                    title="Web"
                    className="linktag"
                    >
                      <motion.div
                        className="link"
                        whileTap={{ scale: 1 }}
                        whileHover={{scale: 1.1}}
                        >
                        <motion.img src={globe} className="logo"/>
                        <p>Web</p>
                      </motion.div>
                  </a>}
                </div>
              </motion.div>
            </div>
          }
        </div>
    );
};