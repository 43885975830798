import "./About.css";
import { motion, useAnimation } from "framer-motion";
import { Skill } from "../Skill/Skill";
import me from "../../image/me.jpg";
import Twitter from "../../icon/Twitter.svg";
import Qiita from "../../icon/Qiita.png";
import note from "../../icon/note.svg";
import { skills } from "../../data/skills";
import MediaQuery from "react-responsive";

export function About() {
  const intro =
    "1995年生まれ。秋田県出身。実家にインターネット環境が無かった反動で幼い頃からITに興味を持つ。専門学校卒業後、地方のIT企業に就職。開発者としてWidows向けデスクトップアプリの開発に携わる。プライベートではWeb系の言語を使ったアプリ開発を行っていた。現在はフリーランスエンジニアとして活動中。フロントエンド、特にUI/UX周りの見た目にこだわったアプリを作成するのが得意。";

  return (
    <div style={{ height: "auto" }}>
      <MediaQuery query="(min-width: 1000px)">
        <img src={me} id="me" />
      </MediaQuery>
      <div className="namesocial">
        <MediaQuery query="(max-width: 999px)">
          <img src={me} id="me_mobile" />
        </MediaQuery>
        <h1 id="name">Junya Shiota</h1>
        <div className="social">
          <a
            href="https://twitter.com/jsalt_0525"
            rel="noopener noreferrer"
            target="_blank"
            title="Twitter"
          >
            <motion.img src={Twitter} className="socialicon" />
          </a>
          <a
            href="https://qiita.com/beniho"
            rel="noopener noreferrer"
            target="_blank"
            title="Qiita"
          >
            <motion.img src={Qiita} className="socialicon" />
          </a>
          <a
            href="https://note.com/jshiota/"
            rel="noopener noreferrer"
            target="_blank"
            title="note"
          >
            <motion.img src={note} className="socialicon" />
          </a>
        </div>
        <MediaQuery query="(max-width: 999px)">
          <p id="intro_mobile">{intro}</p>
          <p id="skillmemo_mobile">以下の技術を使うことができます。</p>
          <div className="grpSkill_mobile">
            {skills.map((skill) => {
              return (
                <Skill
                  icon={skill.icon}
                  name={skill.name}
                  since={skill.since}
                  level={skill.level}
                />
              );
            })}
          </div>
        </MediaQuery>
        <MediaQuery query="(min-width: 1000px)">
          <p
            style={{
              width: "100%",
              color: "gray",
              position: "absolute",
              left: 0,
            }}
          >
            {intro}
          </p>
        </MediaQuery>
      </div>
      <MediaQuery query="(min-width: 1000px)">
        <div className="grpSkillall">
          <p id="skillmemo">以下の技術を使うことができます。</p>
          <div className="grpSkill">
            {skills.map((skill) => {
              return (
                <Skill
                  icon={skill.icon}
                  name={skill.name}
                  since={skill.since}
                  level={skill.level}
                />
              );
            })}
          </div>
        </div>
      </MediaQuery>
    </div>
  );
}
