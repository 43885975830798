import cachette from '../image/cachette.png'
import fashion from '../image/fashion.png'
import fucus from '../image/fucus.png'
import kondate from '../image/kondate.png'
import mycale from '../image/mycale.png'
import Tanondar from '../image/Tanondar.png'
import Tenki from '../image/Tenki.png'
import TwoChoice from '../image/Two-Choice.png'
import SalaryPig from '../image/SalaryPig.png'

export const items = [
    {
      id: "1",
      name: "cachette",
      icon: cachette,
      img: [],
      kind: "iOS / Android / web",
      overview: "cachetteはお店の出店・予約管理ができるアプリです。QRコードを使用して予約を行うため、アプリ内のお店=カシェットで発行したQRコードを知っている人しかそのお店の予約ができません。「cachette」という名の通り、隠れ家的なお店で使用していただく想定で作成しました。",
      date: "2021.07.03",
      skills: ["ionic", "capacitor", "HTML", "CSS", "TypeScript", "Angular", "React", "Firebase", "Adobe XD"],
      link_ios: "https://apps.apple.com/jp/app/cachette/id1565488450",
      link_android: "https://play.google.com/store/apps/details?id=jp.JShiota.cachette",
      link_web: "https://cachette.me/"
    },
    {
      id: "2",
      name: "たのんだー",
      icon: Tanondar,
      img: [],
      kind: "iOS / Android",
      overview: "「あれやってほしい」「これやってほしい」そんなとき気軽にお願いができるアプリです。リマインダーを相手に送ることができたらという発想からこのアプリを作成しました。友達になった相手から「やること」が送られてくると、相手が指定した時間に自分の端末に通知してくれます。",
      date: "2020.05.26",
      skills: ["ionic", "capacitor", "HTML", "CSS", "TypeScript", "Angular", "Firebase", "Adobe XD"],
      link_ios: "https://apps.apple.com/jp/app/%E3%81%9F%E3%81%AE%E3%82%93%E3%81%A0%E3%83%BC/id1500995068",
      link_android: "https://play.google.com/store/apps/details?id=jp.JShiota.Sentifer",
      link_web: ""
    },
    {
      id: "3",
      name: "こんだて！",
      icon: kondate,
      img: [],
      kind: "iOS / Android",
      overview: "シンプルな献立アプリです。献立の作成、履歴管理、買い物リスト作成、日用品リスト作成ができます。初期の頃に作成したアプリのため、ちょっとした勉強感覚で作成しましたが、意外とダウンロード数が多くて驚きました。見た目だけでなく、中身もシンプル設計になっております。",
      date: "2019.10.28",
      skills: ["ionic", "capacitor", "HTML", "CSS", "TypeScript", "Angular", "Adobe XD"],
      link_ios: "https://apps.apple.com/jp/app/%E3%81%93%E3%82%93%E3%81%A0%E3%81%A6/id1428627789",
      link_android: "https://play.google.com/store/apps/details?id=com.kondate.app",
      link_web: ""
    },
    {
      id: "4",
      name: "マイカレ",
      icon: mycale,
      img: [],
      kind: "iOS",
      overview: "とにかくシンプルな手帳みたいなアプリです。妻にお願いされて数日で作成しました。仕様は全て妻に合わせて作成しており、ときどきこここういう風に直してとお願いされてはアップデートを繰り返しております。",
      date: "2021.06.12",
      skills: ["ionic", "capacitor", "HTML", "CSS", "TypeScript", "Angular", "Adobe XD"],
      link_ios: "https://apps.apple.com/jp/app/%E3%83%9E%E3%82%A4%E3%82%AB%E3%83%AC/id1529780329",
      link_android: "",
      link_web: ""
    },
    {
      id: "5",
      name: "おてんき",
      icon: Tenki,
      img: [],
      kind: "iOS",
      overview: "天気の検索・表示はもちろんのこと、このアプリでは指定した時間に天気の状態を通知するアラーム機能も搭載しております。ホーム画面のお天気アイコンは手書き感を出すために綺麗な形をあえて崩して作成しております。また、フォントも全体に合わせて丸いフォントを採用しました。",
      date: "2020.03.22",
      skills: ["ionic", "capacitor", "HTML", "CSS", "TypeScript", "Angular", "Adobe XD"],
      link_ios: "https://apps.apple.com/jp/app/%E3%81%8A%E3%81%A6%E3%82%93%E3%81%8D/id1496783745",
      link_android: "",
      link_web: ""
    },
    {
      id: "6",
      name: "fucus",
      icon: fucus,
      img: [],
      kind: "iOS",
      overview: "自分だけのコスメレビューアプリ。知り合いのデザイナーさんと作りました。デザイン部分は全てデザイナーさんに考えていただき、ロジック部分を自分が担当しました。お互いアプリ開発経験がほとんど皆無でしたが、なんとか形にすることができました。",
      date: "2019.12.03",
      skills: ["ionic", "capacitor", "HTML", "CSS", "TypeScript", "Angular", "Adobe XD"],
      link_ios: "https://apps.apple.com/jp/app/fucus/id1470773970",
      link_android: "",
      link_web: ""
    },
    {
      id: "7",
      name: "Two-Choice",
      icon: TwoChoice,
      img: [],
      kind: "iOS",
      overview: "心理戦ゲームです。専門学校の卒業制作で作ったAndroid版のアプリをiOS版にしてリリースしました。Android版のときの素材をそのまま再利用しましたが、一人でアプリ作成からリリースまでを行ったのはこのアプリが初めてでした。",
      date: "2019.04.05",
      skills: ["swift"],
      link_ios: "https://apps.apple.com/jp/app/two-choice/id1358693056",
      link_android: "",
      link_web: ""
    },
    {
      id: "8",
      name: "fashion",
      icon: fashion,
      img: [],
      kind: "Web",
      overview: "ファッション系なんちゃってECサイトを作ってみました。ある画面だけモバイルとデスクトップでUIを大きく変えた画面があります。Reactを使ってどこまで自分が想像した画面を作成できるか挑戦してみたかったので１画面だけやってみました。全体的にアニメーションが豊富です。",
      date: "2021.12.18",
      skills: ["HTML", "CSS", "TypeScript", "React", "Adobe XD"],
      link_ios: "",
      link_android: "",
      link_web: "https://fashion-js.web.app/"
    },
    {
      id: "9",
      name: "サラリー豚",
      icon: SalaryPig,
      img: [],
      kind: "LINEスタンプ",
      overview: "いつも妻に送信している文言をスタンプ化しました。このスタンプを作成して以降、妻との会話はほとんどスタンプに変わりました。良かったのか良くなかったのかはわかりません。",
      date: "2021.05.29",
      skills: ["Adobe Illustrator Draw", "Adobe XD"],
      link_ios: "",
      link_android: "",
      link_web: "https://store.line.me/stickershop/product/15428261"
    },
  ];
  